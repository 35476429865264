import * as React from "react"


const SCButton = ({children, onClick}) => {
    return(<div className="bg-sc-primary rounded-full shadow-2xl p-2 text-sc-titles  cursor-pointer" onClick={onClick}>
        <div className="text-center">
        {children}
        </div>
        </div>)
}

export default SCButton; 